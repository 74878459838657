import { HttpMethods, APIContexts } from 'enums';
import { apiRequestHandler, apiHost } from '.';
import axios from 'axios';

const apiContext = `${APIContexts.TITLEGROUP}/`;

export const GetTitleGroupLookupAPI = ({ queryParams, onSuccess, ...rest }) => {
  const api = 'GetTitleGroupLookup';
  const endpoint = `${apiHost}${apiContext}${api}`;
  apiRequestHandler({
    endpoint,
    method: HttpMethods.GET,
    onSuccess: (res) => onSuccess(res),
    ...rest,
  });
};

export const ImportTitleGroupAPI = ({ file }) => {
  const formData = new FormData();
  formData.append('file', file);
  const api = 'ImportTitleGroup';
  const url = `${apiHost}${apiContext}${api}`;
  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
