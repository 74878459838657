/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable indent */
import React from 'react';
import { Scheduler } from 'op2mise-react-widgets';
import { Internationalization } from '@syncfusion/ej2-base';
import moment from 'moment';
import { ImportMinutageArchitectureAPI, GetMinutageArchitectureAPI } from "api";
import BootstrapSpinner from 'shared/components/bootstrap-spinner/BootstrapSpinner';
import { formatDateWithMoment } from 'utils';
import { ImportComponent } from './modals/ImportComponent';
import Button from 'shared/components/button/Button';
import data from './ca-minutage.json'
import { getMondayOfTheWeek } from 'utils';
import { addDaysToDate } from 'utils';
import ModifyGradingAndMinutage from './modals/ModifyGradingAndMinutage';
import { isDisabled } from '@testing-library/user-event/dist/utils';

export function CAMinutage({
  schedulerProps,
  channelInfo,
  calculateDates,
  isDirty,
  setIsDirty,
  handleSaveMinutageArchitecture,
  setMinutage,
}) {
  const instance = new Internationalization();

  const [schedules, setSchedules] = React.useState([{}]);
  const [openForm, setOpenForm] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [refreshSchedules, setRefreshSchedules] = React.useState(false);
  const { channelId, channelName } = channelInfo;
  const [unsavedModifiedData, setUnsavedModifiedData] = React.useState([]);
  const blockData = React.useRef({});
  const minutage = React.useRef({});
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  // Getting date range of today and tomorrow
  // NOTE: Use addDaysToDate from date.utils.js
  const tomorrow = moment()
    .add(1, 'days')
    .toDate();

  const period = `${formatDateWithMoment(
    new Date().toDateString(),
  )} - ${formatDateWithMoment(tomorrow)}`;

  // Date Header Template
  const dateHeaderTemplate = (args) => {
    const day = instance.formatDate(args.date, { skeleton: 'E' });
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          className="e-header-day"
          style={{ flex: 1, display: 'flex', justifyContent: 'center' }}
        >
          {day}
        </div>
      </div>
    );
  };

  const eventFields = (args) => ({
    Minutage: args.minutage,
    Id: args.id,
    Subject: args.minutage,
    ProgramType: args.programmeType,
    StartTime: calculateDates(args.dayOfWeek, 1, args.startTime, args.endTime, { startDate: getMondayOfTheWeek(new Date()), endDate: addDaysToDate(getMondayOfTheWeek(new Date()), 6) })['startTime'],
    EndTime: calculateDates(args.dayOfWeek, 1, args.startTime, args.endTime, { startDate: getMondayOfTheWeek(new Date()), endDate: addDaysToDate(getMondayOfTheWeek(new Date()), 6) })['endTime'],
  });

  const eventTemplate = (args) => {
    const { Minutage, Id, Subject, ProgramType, StartTime, EndTime, } = args;
    return (
      <div
        onContextMenu={() => {
          blockData.current = { Minutage, Id, Subject, ProgramType, StartTime, EndTime };
        }}
        className={`e-schedule-custom-event `}
      >
        <div className="e-schedule-custom-event-title e-schedule-minutage-title">
          {Minutage.toFixed(2)}
        </div>
      </div>
    );
  };

  const tooltipTemplate = (args) => {
    return (
      <div className='tooltip-wrap'>
        <div className='content-area'>
          <div className='event-name'>
            <span>{args.Minutage.toFixed(2)}</span>
          </div>
        </div>
      </div>
    );
  }

  const undoItem = () => {
    if (unsavedModifiedData && unsavedModifiedData.length > 0) {
      // Get last action in unsavedModifiedData
      const highestIndex = unsavedModifiedData.length - 1;
      // Revert the last action and remove from array
      const lastAction = unsavedModifiedData[highestIndex];
      const { action, minutage } = lastAction;
      if (action === 'import') {
        // If action is import, remove all imported data
        const removedItem = unsavedModifiedData.splice(highestIndex, 1);
        if (unsavedModifiedData.length === 0) {
          setIsDirty(false);
        }
        setUnsavedModifiedData([...unsavedModifiedData]);
        setSchedules({ minutage: minutage });
        setMinutage({ minutage: minutage });
      } else {
        // If action is modify, remove the last action from the array
        const revertData = minutage.map((schedule) => {
          if (schedule.id === lastAction.Id) {
            return { ...schedule, minutage: lastAction.Minutage };
          }
          return schedule;
        })
        const removedItem = unsavedModifiedData.splice(highestIndex, 1);
        if (unsavedModifiedData.length === 0) {
          setIsDirty(false);
        }
        setUnsavedModifiedData([...unsavedModifiedData]);
        setSchedules({ minutage: revertData });
        setMinutage({ minutage: revertData });
      }
    } else {
      setIsDirty(false);
    }
  };

  const customFooter = () => {
    return (
      <div style={{ marginTop: '15px', float: 'right' }}>
        <Button
          text="Save"
          style={{ marginLeft: '10px' }}
          onClick={() => { handleSaveMinutageArchitecture(schedules), setRefreshSchedules(true) }}
          disabled={!isDirty}
        />
      </div>
    );
  };

  const props = React.useMemo(
    () => ({
      ...schedulerProps,
      dataSource: schedules,
      eventLookupList: 'minutage',
      loading: isLoading,
      suppressExporting: true,
      suppressQuickInfo: true,
      suppressTooltip: false,
      dateHeaderTemplate,
      eventTemplate,
      eventFields,
      onImport: () => {
        // Put your import logic here
        setOpenForm('IMPORT');
      },
      onSlotModified: () => { setIsDialogOpen(true) },
      settings: ['SLOT DURATION', 'SLOT INTERVAL'],
      customTooltipTemplate: tooltipTemplate,
      timeoutDelay: 800,
      actionButtonPanel: customFooter,
      heightBuffer: 230,
      onUndo: undoItem,

    }),
    [schedules, isLoading],
  );

  const RenderScheduler = React.useCallback(() => {
    return (channelId === 0) && !channelName
      ? null
      : (<Scheduler {...props} />)
  }, [props])

  const modifyMinutageProps = React.useMemo(() => ({
    isOpen: isDialogOpen,
    closeModal: () => { setIsDialogOpen(false) },
    handleUpdate: (newMinutage) => {
      minutage.current = newMinutage;
    },
    blockData: blockData.current,
    type: 'minutage',
  }), [blockData.current, isDialogOpen]);


  const cleanOutInvalidMinutage = (data) => {
    return data.filter((schedule) => schedule.minutage !== null && schedule.minutage !== undefined && schedule.minutage !== 0);
  }

  React.useEffect(() => {
    setIsDialogOpen(false);
    blockData.current = {};
    minutage.current = 0;
  }, [])

  React.useEffect(() => {
    // Disables 'Save' button when there are no unsaved changes
    if (unsavedModifiedData.length === 0)
      setIsDirty(false);
  }, [unsavedModifiedData])

  React.useEffect(() => {
    if (minutage.current > 0) {
      const minutageList = [...schedules.minutage];
      const updatedSchedules = minutageList.map((schedule) => {
        if (schedule.id === blockData.current.Id) {
          if (minutage.current !== blockData.current.Minutage) {
            setUnsavedModifiedData([...unsavedModifiedData, { ...blockData.current, action: 'modify', minutage: schedules.minutage }]);
            setIsDirty(true);
          }
          return {
            ...schedule,
            minutage: minutage.current,
          }
        }
        return schedule;
      });
      if (updatedSchedules.length > 0) {
        setSchedules({ minutage: updatedSchedules });
        setMinutage({ minutage: updatedSchedules });
        minutage.current = 0;
      }
    }
  }, [minutage.current]);

  React.useEffect(() => {
    if (channelId || refreshSchedules) {
      GetMinutageArchitectureAPI({
        queryParams: { channelId },
        onSuccess: (response) => {
          const validMinutage = cleanOutInvalidMinutage(response);
          setUnsavedModifiedData([])
          setSchedules({ minutage: [...validMinutage] });
          setMinutage({ minutage: [...validMinutage] });
          setRefreshSchedules(false);
        },
        setLoader: setIsLoading,
      });
    }
  }, [channelId, refreshSchedules]);

  return (
    <>
      {' '}
      {openForm === 'IMPORT' && (
        <ImportComponent
          closeModal={() => setOpenForm('')}
          setIsDirty={setIsDirty}
          setIsLoading={setIsLoading}
          scheduleInfo={{ ...channelInfo, period }}
          schedulerData={{
            schedules: schedules.minutage,
            scheduleInfo: {
              ...channelInfo,
              period,
            },
          }}
          sampleTemplate="minutageImportTemplate"
          handleOnImportApi={ImportMinutageArchitectureAPI}
          importResultColumns={[{
            field: 'dayOfWeek',
            headerText: 'Day of Week',
          }, {
            field: 'startTime',
            headerText: 'Start Time',
          }, {
            field: 'endTime',
            headerText: 'End Time',
          }, {
            field: 'minutage',
            headerText: 'Minutage',
          }]}
          importResultFileTitle="minutage_import_result"
          calculateDates={calculateDates}
          setSchedules={(data) => {
            // Include import action to undo list
            setUnsavedModifiedData([...unsavedModifiedData, { minutage: schedules.minutage, action: 'import' }]);
            setSchedules({ minutage: data })
            setMinutage({ minutage: data });
            setIsLoading(false);
          }}
        />
      )}
      {isDialogOpen && (
        <ModifyGradingAndMinutage
          {...modifyMinutageProps}
        />
      )}
      {isLoading
        ? <div style={{ height: 'calc(100vh - 100px)', overflow: 'hidden !important' }}><BootstrapSpinner /></div>
        : <>
          <RenderScheduler />
        </>}
    </>
  );
}

export default CAMinutage;
