import React, { useRef } from 'react'
import '../architecture.module.css'
import { Internationalization } from '@syncfusion/ej2-base';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { getHourAndMinutes } from 'utils';
import { NumericTextbox } from 'op2mise-react-widgets';
import '../ChannelArchitecture.css';
const ModifyGradingAndMinutage = (props) => {
    const {
        isOpen,
        closeModal,
        handleUpdate,
        blockData,
        type,
    } = props

    const instance = new Internationalization();
    const value = useRef(0)
    const [isSame, setIsSame] = React.useState(false);
    const getLongDay = (date) => {
        return instance.formatDate(new Date(date), { skeleton: 'E' })
    }

    const NumericlInputfield = React.useCallback(() => {
        value.current = type === 'grade' ? blockData.Grade : parseFloat(blockData.Minutage.toFixed(2));
        return (
            <NumericTextbox
                format={type === 'grade' ? '##' : null}
                value={value.current}
                onChange={(e) => {
                    value.current = e.target.value
                }}
                min={type === 'grade' ? 1 : 1}
                max={type === 'grade' ? 10 : null}
                showSpinButton={false}
                enabled={true}
                cssClass="e-op2mise-numeric-textbox"
                width='254px'
                style={{
                    fontSize: '12px',
                    color: '#6D6D73',
                    textAlign: 'start',
                    padding: '0px 15px',
                    height: '34px !important',
                    margin: '0px',
                }}
                label=""
            />
        )
    }, [type])

    return (
        <DialogComponent
            cssClass='e-op2mise-dialog'
            isModal={true}
            width='456px'
            open={isOpen}
            overlayClick={closeModal}
            radius='5px'
            buttons={[
                {
                    click: () => { closeModal() },
                    buttonModel: {
                        content: 'Close',
                        cssClass: 'e-flat-btn',
                    },
                },
                {
                    click: () => {
                        if (isSame) closeModal();
                        else {
                            handleUpdate(value.current);
                            closeModal();
                        }
                    },
                    buttonModel: {
                        content: 'Update',
                        cssClass: 'e-primary-btn',

                    },
                },
            ]}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
                padding: type === 'grade' ? '10px 0 0px 10px' : '10px 10px 0 10px',
                height: 'fit-content',
            }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 7,
                        fontSize: '20px',
                    }}
                >
                    Modify Block | { }
                    <div style={{ color: '#6D6D73' }}>
                        {`${getLongDay(blockData?.StartTime)} 
                        ${getHourAndMinutes(blockData?.StartTime)}`}
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                    <label style={{
                        fontWeight: 400,
                        color: '#0C212B',
                    }}>{type === 'grade'
                        ? 'Grading'
                        : 'Minutage'}
                        <span style={{
                            fontSize: '12px',
                        }}>*</span>
                    </label>
                    {NumericlInputfield()}
                </div>
            </div>
        </DialogComponent >
    )
}

export default ModifyGradingAndMinutage