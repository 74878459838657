import React, { useState, useRef, useEffect } from 'react';
import ScheduleService from 'components/schedule/ScheduleService';
import DateService from 'core/date.service';
import AuthService from 'core/auth.service';
import ImportFileModalComponent from 'components/schedule/react-scheduler/forms/import/ImportFileModalComponent';
import ImportLoadingModalComponent from 'components/schedule/react-scheduler/forms/import/ImportLoadingModalComponent';
import ImportConfirmModalComponent from 'components/schedule/react-scheduler/forms/import/ImportConfirmModalComponent';
import { queryParams } from '@syncfusion/ej2-base';
import ImportConfirmationModalComponent from 'components/channel-profiles/business-rules/modals/ImportConfirmationModalComponent';
import { close } from '@sentry/react';
import ImportResultModalComponent from './ImportResultModalComponent';
import styles from '../../schedule/react-scheduler/forms/import/ImportSlots.module.css';
import moment from 'moment';
import XLSX from 'sheetjs-style';
import { dayOfWeek } from '@progress/kendo-date-math';
import { format } from 'crypto-js';

export function ImportComponent({
  closeModal,
  setScheduleData,
  scheduledata = [],
  clockStart = 0,
  scheduleInfo,
  schedulerData,
  setIsDirty,
  sampleTemplate,
  handleOnImportApi,
  importResultColumns,
  importResultFileTitle,
  getUpdatedList,
  calculateDates,
  setSchedules
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [headerData, setHeaderData] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadedList, setUploadedList] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [uploadedSlots, setUploadedSlots] = useState([]);
  const { channelId } = { ...scheduleInfo };
  const { schedules, period } = { ...schedulerData };

  const loadingHeaderComponent = (
    <div className={styles.divHeader}>
      Importing and validating slots from{' '}
      <b>{selectedFile && selectedFile.name}</b>
    </div>
  );

  const isInvalid = (value) => {
    return (
      value === null || value === undefined || value === 0 || value.length === 0
    );
  };

  // Only for Grading and Minutage
  const cleanOutInvalidSlots = (data) => {
    data.map((schedule) => {
      if (sampleTemplate.includes('grade')) {
        if (isInvalid(schedule.grade)) {
          schedule.imported = false;
          schedule.result = 'Invalid grade input. Must be between 1 and 10';
        }
      } else if (sampleTemplate.includes('minutage')) {
        if (isInvalid(schedule.minutage)) {
          schedule.imported = false;
          schedule.result = 'Invalid minutage input.';
        }
      }
    });
  };

  const validateImportedFile = (data, importResultColumns) => {
    let columnHeaders = Object.keys(data[0]).map((header) => header.toLowerCase());
    let validColumns = [];
    if (importResultColumns.length > 0) {
      importResultColumns.map((column) => {
        const field = column.field.toLowerCase();
        if (field) {
          validColumns.push(columnHeaders.includes(field) ? true : false);
        }
      })
    }
    return !validColumns.includes(false);
  }

  const validateRows = (data) => {
    const fieldConstraints = {
      week: {
        min: 1,
        max: 52,
        type: 'number',
      },
      dayOfWeek: {
        min: 1,
        max: 7,
        type: 'number',
      },
      startTime: {
        type: 'string',
        format: 'HH:mm:ss',
      },
      endTime: {
        type: 'string',
        format: 'HH:mm:ss',
      },
      grade: {
        min: 1,
        max: 10,
        type: 'number',
      },
      minutage: {
        type: 'string',
      },
      imported: {
        type: 'boolean',
      },
      result: {
        type: 'string',
      },
      type: {
        type: 'string',
        options: ['Movies', 'Series', 'Placeholder', 'TitleGroup'],
      },
      genre: {
        type: 'string',
      },
    }

    // Validate each field value
    data.map((schedule) => {
      importResultColumns.map((column) => {
        const value = schedule[column.field];
        const constraints = fieldConstraints[column.field];
        if (constraints?.type === 'number' && isNaN(value) || constraints?.type === 'float' && isNaN(value)) {
          schedule.imported = false;
          schedule.result = `Invalid ${column.headerText} input. Must be a number.`;
        } else if (constraints?.type === 'number' && constraints?.min && constraints?.max) {
          if (value < constraints?.min || value > constraints?.max) {
            schedule.imported = false;
            schedule.result = `Invalid ${column.headerText} input. Must be between ${constraints?.min} and ${constraints?.max}.`;
          }
        } else if (constraints?.type === 'string' && !value) {
          schedule.imported = false;
          schedule.result = `Invalid ${column.headerText} input. Must be a string.`;
        } else if (constraints?.type === 'string' && constraints?.options && !constraints?.options.includes(value)) {
          schedule.imported = false;
          schedule.result = `Invalid ${column.headerText} input. Must be one of ${constraints?.options.join(', ')}.`;
        } else if (constraints?.format === 'HH:mm:ss' && !moment(value, 'HH:mm:ss', true).isValid()) {
          schedule.imported = false;
          schedule.result = `Invalid time input. Must be in HH:mm:ss format.`;
        } else if (isInvalid(value)) {
          schedule.imported = false;
          schedule.result = `Invalid ${column.headerText} input.`;
        }
      });
    });

    return data;
  }

  // Helper function to convert decimal time to HH:mm:ss format
  const convertToTime = (decimalTime) => {
    const duration = moment.duration(Math.floor(decimalTime * 86400000), 'milliseconds');
    return moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
  };

  const architectureTab = sampleTemplate.includes('grade')
    ? 'Grading'
    : sampleTemplate.includes('minutage')
      ? 'Minutage'
      : 'Program';

  const getBlockName = (day, week, date) => {
    const hour = date.toTimeString().split(':')[0];
    const min = date.toTimeString().split(':')[1];

    const time = `${hour}:${min}`;
    return `${day}${week}${time}`;
  };

  const removeImportAndResultFields = (data) => {
    let temp = [...data];
    const cleanedData = temp.map((schedule) => {
      schedule = Object.keys(schedule).reduce((object, key) => {
        if (key !== 'imported' && key !== 'result') {
          object[key] = schedule[key];
        }
        return object;
      }, {});
      return schedule;
    })
    return cleanedData;
  }

  const constructData = React.useCallback((schedule, index) => {
    let tempSchedule = {};
    switch (architectureTab) {
      case 'Grading':
        tempSchedule = {
          id: index,
          channelID: channelId,
          dayOfWeek: schedule.DayOfWeek,
          startTime: schedule.StartTime,
          endTime: schedule.EndTime,
          grade: schedule.Grade,
          value: 0,
          minutage: schedule?.Minutage ?? 0,
          imported: true,
          result: "Success",
        }
        break;
      case 'Minutage':
        tempSchedule = {
          id: index,
          channelID: channelId,
          dayOfWeek: schedule.DayOfWeek,
          startTime: schedule.StartTime,
          endTime: schedule.EndTime,
          grade: schedule?.Grade ?? 0,
          value: 0,
          minutage: parseFloat(schedule.Minutage),
          imported: true,
          result: "Success",
        }
        break;

    }
    return tempSchedule;
  }, [architectureTab, channelId, period])

  const [csvData, setCsvData] = React.useState([]);
  const uploadFile = async (file, fileExtension, overwrite) => {
    setIsLoading(true);
    if (architectureTab !== 'Program') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        if (!validateImportedFile(jsonData, importResultColumns)) {
          setErrorMessage(
            'Invalid file format. Please use files in the suggested format above (see sample template)'
          );
          setConfirmModal(false);
          setIsDirty(false);
          setUploadSuccess(false);
          setIsLoading(false);
          return;
        }
        // Convert StartTime and EndTime to time format of 00:00:00
        const importedData = jsonData.map((schedule, index) => {
          if (schedule.StartTime !== null && schedule.EndTime !== null) {
            // from decimal time to HH:mm:ss
            if (typeof schedule.StartTime === 'number') {
              schedule.StartTime = convertToTime(schedule.StartTime);
              schedule.EndTime = convertToTime(schedule.EndTime);
            } else {
              schedule.StartTime = `${schedule.StartTime}:00`;
              schedule.EndTime = `${schedule.EndTime}:00`;
            }
            return constructData(schedule, index + 1);
          }
        });
        const validatedImportedData = validateRows(importedData);
        if (validatedImportedData.length > 0) {
          if ((schedules?.length > 0) && !overwrite) {
            setConfirmModal(true);
            setIsLoading(false);
            setIsDirty(false);
            setUploadedList();
          } else {
            setUploadedList(validatedImportedData);
            setIsDirty(true);
            setUploadSuccess(true);
            setConfirmModal(false);
            setIsLoading(false);
            setSchedules(removeImportAndResultFields(validatedImportedData));
          }
        }
      }

      reader.readAsArrayBuffer(file);
    } else {
      try {
        const response = await handleOnImportApi(channelId, file);
        if (response) {
          if (
            (response.data.channelArchitectureExist) &&
            !overwrite
          ) {
            setConfirmModal(true);
            setIsLoading(false);
            setIsDirty(false);
            setUploadedList();
          } else {
            if (response.data.channelArchitectureModel) {
              getUpdatedList(channelId);
            }
            cleanOutInvalidSlots(response.data.channelArchitectureModel);
            setUploadedList(response.data.channelArchitectureModel);
            setIsDirty(true);
            setUploadSuccess(true);
            setIsLoading(false);
          }
        }
      } catch (err) {
        if (err.message.includes('400')) {
          setErrorMessage(
            'Invalid file format. Please use files in the suggested format above (see sample template)'
          );
        } else if (err.message.includes('500')) {
          setErrorMessage('Error uploading file. Please try again later');
          console.error(err);
        }
        setConfirmModal(false);
        setIsDirty(false);
        setUploadSuccess(false);
        setIsLoading(false);
      }
    }
  };

  const handleOnImportFile = () => {
    const acceptableFiles = ['xlsx', 'xls', 'csv'];
    const fileExtension = selectedFile.name.split('.').pop();
    if (acceptableFiles.includes(fileExtension)) {
      if (scheduledata.length > 0) setConfirmModal(true);
      else uploadFile(selectedFile, fileExtension, false);
    } else {
      setErrorMessage(
        'Uploaded file type is not supported. Please use files in the following format (xlsx, csv)'
      );
    }
  };

  const handleOverwrite = () => {
    const fileExtension = selectedFile.name.split('.').pop();
    uploadFile(selectedFile, fileExtension, true);
  };

  return isLoading ? (
    <ImportLoadingModalComponent headerComponent={loadingHeaderComponent} />
  ) : uploadSuccess ? (
    <ImportResultModalComponent
      uploadedList={uploadedList}
      closeModal={closeModal}
      scheduleInfo={scheduleInfo}
      importResultColumns={importResultColumns}
      importResultFileTitle={importResultFileTitle}
    />
  ) : confirmModal ? (
    <ImportConfirmationModalComponent
      show={confirmModal}
      onHide={closeModal}
      onProceed={handleOverwrite}
      message="A list already exists for this channel. Importing overwrites all existing data for this channel."
    />
  ) : (
    <ImportFileModalComponent
      closeModal={closeModal}
      selectedFile={selectedFile}
      setSelectedFile={(e) => {
        setSelectedFile(e);
        setErrorMessage('');
      }}
      handleOnImportFile={handleOnImportFile}
      errorMessage={errorMessage}
      setErrorMessage={(e) => setErrorMessage(e)}
      sampleTemplate={sampleTemplate}
    />
  );
}
